import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Experience = ({ frontmatter, html }) => {
  const {
    name,
    image,
    start_date,
    end_date,
    market,
    skills,
    role,
  } = frontmatter
  const display_date = [start_date, end_date].filter(e => e).join(" - ")
  return (
    <div className="o-layout u-mb">
      <div className="o-layout__item u-1-of-1-mobile u-1-of-4-tablet">
        {image && (
          <Img
            fluid={image.childImageSharp.fluid}
            alt={name}
            className="u-mb b--solid b-light-grey b--thin "
            title={name}
          />
        )}
      </div>
      <div className="o-layout__item u-1-of-1-mobile u-3-of-4-tablet">
        <h3>{name}</h3>

        <h4>
          {start_date} to {end_date || "Present"}
        </h4>

        <dl className="o-layout u-mt- u-mb-">
          <dt className="o-layout__item u-1-of-8-tablet u-1-of-3-mobile u-m0">
            Market
          </dt>
          <dd className="o-layout__item u-7-of-8-tablet u-2-of-3-mobile u-m0">
            {market}
          </dd>
          <dt className="o-layout__item u-1-of-8-tablet u-1-of-3-mobile u-m0">
            Role
          </dt>
          <dd className="o-layout__item u-7-of-8-tablet u-2-of-3-mobile u-m0">
            {role}
          </dd>
          <dt className="o-layout__item u-1-of-8-tablet u-1-of-3-mobile u-m0">
            Skills
          </dt>
          <dd className="o-layout__item u-7-of-8-tablet u-2-of-3-mobile u-m0">
            {skills}
          </dd>
        </dl>

        <ul
          className="project-copy"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
  )
}

const Page = ({ data }) => (
  <Layout>
    <SEO title="Experience" />
    <a className="anchor" id="experience" />
    <section>
      <h1>Experience</h1>
      {data.allMarkdownRemark.edges.map(edge => (
        <Experience key={edge.node.id} {...edge.node} />
      ))}
    </section>
  </Layout>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/experiences/" } }
      sort: { fields: frontmatter___start_date, order: DESC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            name
            market
            start_date(formatString: "MMMM YYYY")
            end_date(formatString: "MMMM YYYY")
            role
            skills
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Page
